<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/registration/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')">

			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Status" v-model="model.status" :validation="$v.model.status" :options="$constants.registration.variant.statusName" :descriptions="$constants.registration.variant.statusDescription" />
			<app-input-text label="Name" :maxlength="64" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" />
			<app-input-text label="Price" prefix="$" placeholder="Enter amount..." v-model="model.price" :validation="$v.model.price" />
			<app-input-text v-if="isTicketType" label="Ticket Quantity" placeholder="Enter amount..." notes="Set the number of tickets that the purchase gives (e.g. Buy 1 Get 1 Free)" :toggle="model.tickets.enabled" v-on:toggle="model.tickets.enabled = !model.tickets.enabled" v-model="model.tickets.amount" :validation="$v.model.tickets.amount" />
			<app-input-text label="Stock Limit" placeholder="Enter amount..." :toggle="model.quantity.enabled" notes="Set a maximum number that can be sold of this variant across all purchases." v-on:toggle="model.quantity.enabled = !model.quantity.enabled" v-model="model.quantity.amount" :validation="$v.model.quantity.amount" />

		</app-content-box>

		<app-content-section v-if="!isNew" route="Convention.Registration.Item.Variant.Comps" :params="{ item: model.item, variant: model.id }" title="Comps" description="Manage any additional items that are comped when this variant is purchased." :count="item.count.comps" />

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/registration/access', 'read'),
			references: {
				status: [],
				parent: false
			},
			model: {
				id: false,
				name: '',
				price: 0,
				quantity: {
					enabled: false,
					amount: 0
				},
				tickets: {
					enabled: false,
					amount: 0
				}
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			quantity: {},
			tickets: {}
		}
	},

	computed: {

		statusOptions: function() {

			return this.references.status

		},

		isTicketType: function() {

			return this.references.parent.type === this.$constants.registration.item.type.ticket

		}

	}

}

</script>

<style scoped>

</style>